"use client"

import { cn } from "@/lib/utils"

const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN

interface LogoutButtonProps {
  className?: string
  variant?: "ghost" | "default"
}

export function LogoutButton({
  className,
  variant = "default",
}: LogoutButtonProps) {
  const handleLogout = () => {
    // Clear client-side storage
    window.localStorage.clear()
    window.sessionStorage.clear()

    // Clear all cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
    })

    // Use Next.js Auth0 logout endpoint
    window.location.href = "/api/auth/logout"
  }

  return (
    <button
      onClick={handleLogout}
      className={cn(
        variant === "ghost"
          ? "text-sm text-muted-foreground hover:text-primary"
          : "text-sm",
        className
      )}
    >
      <span className={cn(variant === "ghost" ? "hidden sm:inline" : "")}>
        Log Out
      </span>
    </button>
  )
}
