"use client"

import Link from "next/link"
import { useUser } from "@auth0/nextjs-auth0/client"

import { Skeleton } from "@/components/ui/skeleton"
import { LogoutButton } from "@/components/logout-button"

import { useHeader } from "./header-context"

export function Header() {
  const { items, activePath } = useHeader()
  const { user, isLoading } = useUser()

  // Show loading skeleton while auth state is being determined
  if (isLoading) {
    return (
      <header className="flex items-center">
        <nav className="ml-auto flex items-center gap-4 lg:gap-6">
          {[...Array(items.length)].map((_, i) => (
            <Skeleton key={i} className="h-8 w-20" />
          ))}
        </nav>
      </header>
    )
  }

  const navigationItems = items.filter((item) => {
    // Auth required routes
    if (item.href === "/dashboard" && !user) return false
    if (item.href === "/api/auth/logout" && !user) return false

    // Non-auth routes
    if (item.href === "/api/auth/login" && user) return false
    if (item.href === "/join" && user) return false

    return true
  })

  return (
    <header className="flex items-center">
      <nav className="ml-auto flex items-center gap-4 lg:gap-6">
        {navigationItems.map((item) => {
          // Use LogoutButton for logout action
          if (item.href === "/api/auth/logout") {
            return (
              <div
                key={item.href}
                className={`flex items-center space-x-1 sm:space-x-2 ${
                  activePath === item.href
                    ? "text-primary"
                    : "text-secondary-950 hover:text-primary"
                }`}
              >
                {item.icon && <item.icon className="h-4 w-4" />}
                <LogoutButton
                  variant="ghost"
                  className={`text-sm sm:text-base ${
                    activePath === item.href
                      ? "text-primary"
                      : "text-secondary-950 hover:text-primary"
                  }`}
                />
              </div>
            )
          }

          return (
            <Link
              key={item.href}
              href={item.href}
              prefetch={item.href?.includes("api/auth") ? false : true}
              className={`relative flex items-center space-x-1 text-sm sm:space-x-2 sm:text-base ${
                activePath === item.href
                  ? "text-primary after:absolute after:-bottom-1 after:left-0 after:h-0.5 after:w-full after:bg-primary"
                  : "text-secondary-950 hover:text-primary hover:after:absolute hover:after:-bottom-1 hover:after:left-0 hover:after:h-0.5 hover:after:w-full hover:after:bg-primary"
              }`}
            >
              {item.icon && <item.icon className="h-4 w-4" />}
              <span className="hidden sm:inline">{item.label}</span>
            </Link>
          )
        })}
      </nav>
    </header>
  )
}
